import type { NextPageLink } from '@readme/backend/models/page/types';
import type { ListChildComponentProps } from 'react-window';

import React, { useState, useCallback, useRef, useEffect } from 'react';
import { FixedSizeList } from 'react-window';

import type useClassy from '@core/hooks/useClassy';
import useFuzzySearch from '@core/hooks/useFuzzySearch';

import { useDropdownContext } from '@ui/Dropdown/Context';
import Flex from '@ui/Flex';
import Icon from '@ui/Icon';
import Input from '@ui/Input';
import Menu, { MenuDivider } from '@ui/Menu';
import PageMenuUI, { PageMenuItem, pageMenuHeight, pageMenuItemHeight } from '@ui/PageMenu';

interface Props {
  bem: ReturnType<typeof useClassy>;
  onClick: (page: NextPageLink) => void;
  pages: NextPageLink[];
}

function PageMenu({ bem, pages = [], onClick }: Props) {
  const { isOpen: isDropdownOpen } = useDropdownContext();
  const listRef = useRef<FixedSizeList>(null);
  const [pageFilter, setPageFilter] = useState('');
  const [filteredPages] = useFuzzySearch(pages, pageFilter, { key: 'name', highlight: false });

  const handleOnChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPageFilter(e.target.value);
  }, []);

  // Whenever dropdown is opened, ensure the virtual list is scrolled back to
  // its starting position in the event that the list is never unmounted.
  useEffect(() => {
    if (isDropdownOpen) {
      listRef.current?.scrollToItem(0);
    }
  }, [isDropdownOpen]);

  return (
    <Menu className={bem('-pageMenu')}>
      <Flex align="center" className={bem('-pageMenu-input')} gap={0}>
        <Input onChange={handleOnChange} placeholder="Find a page" prefix={<Icon name="search" />} value={pageFilter} />
      </Flex>
      <MenuDivider />
      <PageMenuUI className={bem('-pageMenu-results')}>
        {filteredPages.length ? (
          <FixedSizeList
            ref={listRef}
            height={Math.min(pageMenuItemHeight * filteredPages.length, pageMenuHeight)}
            itemCount={filteredPages.length}
            itemData={filteredPages}
            itemKey={(index, itemData) => {
              const page = itemData[index];
              return `${index}-${page.slug}`;
            }}
            itemSize={pageMenuItemHeight}
            overscanCount={2}
            width="100%"
          >
            {({ data, index, style }: ListChildComponentProps<NextPageLink[]>) => {
              const page = data[index];
              return <PageMenuItem {...page} onClick={onClick} style={style} />;
            }}
          </FixedSizeList>
        ) : (
          <Flex align="center" className={bem('-pageMenu-results_empty')} gap="1" justify="center">
            No Results
          </Flex>
        )}
      </PageMenuUI>
    </Menu>
  );
}

export default PageMenu;
